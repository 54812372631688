import { FieldAssistantType } from "domain/assistant";
import { noop } from "lodash";
import {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { AssistantFloatingChat } from "./AssistantFloatingChat";
import { ConfirmationModal } from "../Modal";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

interface IAssistantFloatingChatContext {
  openChat: (dealId: string, fieldId: string, type: FieldAssistantType) => void;
  closeChat: () => void;
  chatPayload?: {
    dealId: string;
    fieldId: string;
    type: FieldAssistantType;
  };
}

const AssistantFloatingChatContext =
  createContext<IAssistantFloatingChatContext>({
    openChat: noop,
    closeChat: noop,
  });

export const useAssistantFloatingChatContext = () => {
  return useContext(AssistantFloatingChatContext);
};

type RemovePayloadModalType = "delete" | "replace";

interface IRemovePayloadModalRequest {
  type: RemovePayloadModalType;
  payload?: IAssistantFloatingChatContext["chatPayload"];
}

export const AssistantFloatingChatProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const [removePayloadModalType, setRemovePayloadModalType] =
    useState<IRemovePayloadModalRequest | null>(null);
  const [chatPayload, setChatPayload] = useState<
    IAssistantFloatingChatContext["chatPayload"] | undefined
  >();
  const { pathname } = useLocation();

  const openChat = useCallback(
    (dealId: string, fieldId: string, type: FieldAssistantType) => {
      setChatPayload({ dealId, fieldId, type });
    },
    [setChatPayload]
  );

  const requestNewChat = useCallback<IAssistantFloatingChatContext["openChat"]>(
    (dealId, fieldId, type) => {
      if (chatPayload && fieldId !== chatPayload.fieldId) {
        return setRemovePayloadModalType({
          type: "replace",
          payload: { dealId, fieldId, type },
        });
      }

      return openChat(dealId, fieldId, type);
    },
    [chatPayload, openChat]
  );

  const closeChat = useCallback(() => {
    setChatPayload(undefined);
  }, []);

  const requestCloseChat = useCallback(() => {
    return setRemovePayloadModalType({ type: "delete" });
  }, []);

  const onModalClose = useCallback(() => {
    return setRemovePayloadModalType(null);
  }, []);

  const onModalConfirm = useCallback(() => {
    if (!removePayloadModalType) return;

    const { type, payload } = removePayloadModalType;

    try {
      switch (type) {
        case "replace":
          return openChat(
            payload?.dealId as string,
            payload?.fieldId as string,
            payload?.type as FieldAssistantType
          );
        case "delete":
          return closeChat();
      }
    } finally {
      onModalClose();
    }
  }, [closeChat, onModalClose, openChat, removePayloadModalType]);

  useEffect(() => {
    if (typeof pathname === "string") {
      closeChat();
    }
  }, [closeChat, pathname]);

  return (
    <AssistantFloatingChatContext.Provider
      value={{
        openChat: requestNewChat,
        closeChat: requestCloseChat,
        chatPayload,
      }}
    >
      {children}

      {chatPayload && (
        <AssistantFloatingChat
          dealId={chatPayload.dealId}
          fieldId={chatPayload.fieldId}
          type={chatPayload.type}
          key={chatPayload.fieldId}
        />
      )}

      {removePayloadModalType && (
        <ConfirmationModal
          title={
            removePayloadModalType.type === "delete"
              ? "Delete thread"
              : "Replace thread"
          }
          isOpen
          onClose={onModalClose}
          onConfirm={onModalConfirm}
          onCancel={onModalClose}
        >
          <div>
            <Typography variant="body2" component="p">
              {removePayloadModalType?.type === "delete"
                ? "Are you sure you want to delete this thread?"
                : "Are you sure you want to replace the current thread?"}
            </Typography>
            <Typography variant="body2" className="italic mt-2" component="p">
              The progress made in this thread will be lost, necessitating the
              generation of new content.
            </Typography>
          </div>
        </ConfirmationModal>
      )}
    </AssistantFloatingChatContext.Provider>
  );
};
