import { useDrag } from "@use-gesture/react";
import { useState } from "react";
import { config, useSpring } from "react-spring";

interface IUseResizableMenuOptions {
  initialBarSize: number;
}

export const useResizableMenu = (
  options: IUseResizableMenuOptions = { initialBarSize: 528 }
) => {
  const [isMenuCollapsed, setMenuCollapsed] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const [{ width }, api] = useSpring(() => ({
    width: options.initialBarSize,
    config: { ...config.stiff },
  }));

  const bindDrag = useDrag(
    ({ offset, dragging }) => {
      setDragging(dragging || false);

      api.set({ width: offset[0] });
    },
    {
      bounds: {
        left: options.initialBarSize,
      },
      from: [width.get(), 0],
    }
  );

  return {
    isMenuCollapsed,
    setMenuCollapsed,
    bindDrag,
    width,
    isDragging,
  };
};
